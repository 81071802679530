import React, { FC } from "react"
import { graphql } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import { SanitySimplePage } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import * as s from "./privacy-policy.module.scss"

type Props = {
  data: {
    privacyPolicy: SanitySimplePage
  }
}

const PrivacyPolicyPage: FC<Props> = ({ data: { privacyPolicy } }) => {
  if (!privacyPolicy) {
    return <h1>Privacy Policy not set at the moment</h1>
  }

  const { title, _rawContent } = privacyPolicy

  return (
    <Container className={s.container}>
      <Row className={s.titleContainer}>
        <Col xs={12}>
          <h1>{title}</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <BaseBlockContent blocks={_rawContent} />
        </Col>
      </Row>
    </Container>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query {
    privacyPolicy: sanitySimplePage(
      handle: { current: { eq: "privacy-policy" } }
    ) {
      title
      _rawContent(resolveReferences: { maxDepth: 20 })
    }
  }
`
